<template>
  <div>
    <div class="flex-between">
      <h2>{{ title }}</h2>

      <el-button class="btn1" type="primary" size="small" @click="offDialog()"
        >返回</el-button
      >
    </div>
    <el-form
      style="width: 500px"
      size="small "
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="问题标签" prop="title">
        <el-input v-model="ruleForm.title"></el-input>
      </el-form-item>

      <el-form-item label="选择分类" prop="type">
        <el-select v-model="ruleForm.type" placeholder="请选择活动区域">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="是否显示" prop="is_open">
        <el-switch v-model="ruleForm.is_open"></el-switch>
      </el-form-item>
    </el-form>

    <div style="margin-left: 30px">问题答案:</div>
    <div ref="editor" @input="editorChange" style="margin: 20px; width: 100%" />

    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="offDialog()">取 消</el-button>
      <el-button size="small" type="primary" @click="addDialog()"
        >确 定</el-button
      >
    </span>
  </div>
</template>
 
<script>
import { typeListApi, klmHelpCenterApi } from "@/api/help_component.js";
// import { getOssAccessInfo } from "../../api/common.js";
import { getOssAccessInfo } from "@/api/common.js";

// import {bannerInfo} from "../../api/marketing";

import Sha1 from "js-sha1";
var OSS = require("ali-oss");
import E from "wangeditor";

export default {
  name: "help_component",
  props: {
    row: {
      type: Object,
      default() {
        return { sklm_help_center_id: null };
      },
    },
  },
  data() {
    return {
      editor: "",

      title: "",
      ruleForm: {
        title: "",
        type: "", //id
        is_open: false,
        content: "", //富文本内容
        sklm_help_center_id: "", //帮助中心id
      },

      options: [],

      rules: {
        title: [{ required: true, message: "请输入问题标签", trigger: "blur" }],
        type: [{ required: true, message: "请选择分类", trigger: "change" }],
      },
    };
  },

  created() {
    this.typeList();
    this.ruleForm = this.row;
    this.initData();
    this.editShow();
  },

  methods: {
    //  标题
    editShow() {
      if (this.row.sklm_help_center_id) {
        this.title = "编辑回答";
        this.ruleForm = this.row;
      } else {
        this.title = "新建回答";
      }
    },

    // 富文本
    initData(id) {
      let _this = this;

      setTimeout(() => {
        const editor = new E(_this.$refs.editor);
        this.editor = editor;
        editor.config.zIndex = 10;
        editor.config.customUploadImg = function (files, insert) {
          files.forEach((image) => {
            getOssAccessInfo().then((res) => {
              const client = new OSS({
                region: "oss-cn-hangzhou",
                accessKeyId: res.data.access_key_id,
                accessKeySecret: res.data.access_key_secret,
                bucket: "vodm-store-prod",
                stsToken: res.data.security_token,
              });
              console.log(client);
              let randomStr = "";
              for (var i = 0; i < 9; i++) {
                randomStr =
                  randomStr + Math.floor(Math.random() * 10).toString();
              }
              let fileName =
                "/merchantscenter/banner/" +
                Sha1(
                  _this.$store.state.token + new Date().getTime() + randomStr
                ) +
                image.type;
              console.log(fileName);
              client.put(fileName, image).then((res) => {
                console.log(JSON.stringify(res));
                let imgUrl = "https://store.paitk.com/" + fileName;
                insert(imgUrl);
              });
            });
          });
        };
        editor.config.onchange = (html) => {
          this.ruleForm.content = html; //富文本内容
        };
        editor.create();
         editor.txt.html(_this.row.content);
      }, 100);
    },

    editorChange(e) {
      this.htmlContent = e.target.innerHTML;
    },

    // 头部tab
    async typeList() {
      const res = await typeListApi({ pageSize: "999" });
      console.log("头部tab", res);
      this.options = res.data.list;
    },

    // 新增分类提交
    async addDialog() {
      await this.$refs.ruleForm.validate();
      const res = await klmHelpCenterApi(this.ruleForm);
      if (res.status == 1) {
        this.typeList();
        this.offDialog();
        if (this.ruleForm.sklm_help_center_id) {
          this.$message.success("编辑成功");
        } else {
          this.$message.success("添加成功");
        }
      } else {
        this.$message.error(res.message);
      }
    },

    // 取消弹框
    offDialog() {
      this.$emit("bagck");
    },

    // 关闭弹窗
    closeDialog() {
      this.AdddialogVisible = false;
      // 表格置空
    },
  },
};
</script>

<style scoped lang='scss'>
.flex-left {
  display: flex;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>