<template>
  <div style="height: 100vh; background: white;padding: 10px;margin: 10px">
    <div style="margin: 3% 10% 10% 10%">
      <div style="display: flex;justify-content: space-between;align-items: center">
        <span>基本信息</span>
        <el-button v-if="editor==true" type="primary" @click="editor=false" size="small">修改</el-button>
        <div v-if="editor==false">
          <el-button size="small" @click="editor=true">取消</el-button>
          <el-button size="small"  type="primary" @click="update">保存</el-button>
        </div>
      </div>
      <div style="display: flex;align-items: center">
        <el-upload
            class="avatar-uploader"
            action=""
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :http-request="uploadImg"
        >
          <img v-if="userInfo.head_portrait" :src="userInfo.head_portrait" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <div style="width: 100%;margin-left: 20px">
          <div style="display: flex;width: 100%;align-items: center">
            <span style="width: 80px">企业名称:</span>
            <el-input :disabled="editor"  v-model="userInfo.name" placeholder="请输入企业名称" size="small"></el-input>
          </div>
          <div style="margin-top: 10px;font-size: 14px;">
            <span style="color: rgba(166, 166, 166, 1);">系统创建时间:</span>
            <span style="margin-left: 10px">{{ userInfo.create_time }}</span>
            <span style="margin-left: 20px;color: rgba(166, 166, 166, 1);">系统到期时间:</span>
            <span style="margin-left: 10px;color: rgba(0, 119, 255, 1);">{{userInfo.expiration_time}}</span>
          </div>
        </div>
      </div>
      <div>

        <el-form :label-position="labelPosition" label-width="180px">
          <el-form-item label="系统ID:">
            <el-input :disabled="true" class="form_input" v-model="userInfo.id" size="small"></el-input>
          </el-form-item>
          <el-form-item label="联系人:">
            <el-input :disabled="editor" class="form_input" v-model="userInfo.contact" placeholder="请输入联系人" size="small"></el-input>
          </el-form-item>
          <el-form-item label="手机号/系统登录账号:">
            <el-input :disabled="editor" class="form_input" v-model="userInfo.mobile_phone" placeholder="请输入手机号/系统登录账号" size="small"></el-input>
          </el-form-item>
          <el-form-item label="密码:">
            <el-input :disabled="editor" class="form_input" v-model="userInfo.password" placeholder="请输入密码" size="small"></el-input>
          </el-form-item>
        </el-form>


      </div>
    </div>
  </div>
</template>

<script>
import { merchantsInfo ,merchantsUpdate  } from "../../api/merchantsUser.js"
import {getOssAccessInfo} from "../../api/common";
import Sha1 from "js-sha1";
var OSS = require("ali-oss");

export default {
  name: "merchantsInfo",
  data(){
    return{
      editor: true,
      labelPosition: 'top',
      userInfo:{}
    }
  },
  mounted(){
    this.initData()
  },
  methods:{
    handleAvatarSuccess(res, file) {
      console.log(JSON.stringify(res))
      console.log(JSON.stringify(file))
    },
    beforeAvatarUpload(file) {
      return true
    },
    uploadImg(res){
      console.log(res)
      console.log(res.file)
      let newFile = res.file
      getOssAccessInfo().then(res => {
        console.log(JSON.stringify(res))
        const client = new OSS({
          region: "oss-cn-hangzhou",
          accessKeyId: res.data.access_key_id,
          accessKeySecret: res.data.access_key_secret,
          bucket: "vodm-store-prod",
          stsToken: res.data.security_token
        });
        console.log(client)
        let randomStr = "" ;
        for (var i = 0; i < 9; i++) {
          randomStr =randomStr +Math.floor(Math.random() * 10).toString();
        }
        let fileName =  "/merchantscenter/banner/" + Sha1(this.$store.state.token + new Date().getTime() + randomStr) + newFile.type;
        console.log(fileName)
        client.put(fileName, newFile).then(res =>{
          console.log(JSON.stringify(res));
          this.userInfo.head_portrait = "https://store.paitk.com/" + fileName;
          this.$message.success("头像上传成功");
          this.update()
        })
      })
    },
    initData(){
      merchantsInfo({}).then((res) => {
        this.userInfo = res.data.merchantsInfo;
      });
    },
    update(){
      merchantsUpdate({},this.userInfo).then((res) => {
        this.editor = true
        this.$message.success("信息修改成功");
      });
    }
  }
}
</script>

<style scoped>

.avatar-uploader{
  border: 1px dashed #757070;
  border-radius: 6px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}

</style>
