<template>
  <div style="background: white;padding: 10px;margin: 10px">
    <div>
      种草任务详情
      <div style="float: right">
        <el-button size="small" style="margin-top: 10px" @click="goBack">返回</el-button>
      </div>
    </div>

    <div style="margin: 20px">
      <div style="color: rgba(56, 56, 56, 1);font-size: 14px;margin: 20px">基本信息</div>
      <div style="display: flex;justify-content: center">
        <iframe :src="form.product_url" style="height: 60vh;"></iframe>
        <el-form :model="form" :rules="rules" ref="ruleForm" :label-position="labelPosition" label-width="160px">
          <el-form-item label="团长商品链接：" required>
            <div>
              <el-input
                  :disabled="true"
                  @blur="doTrillProductInfo"
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4}"
                  v-model="form.product_url" placeholder="请粘贴抖音团长商品推广链接" clearable></el-input>
              <div style="display: flex;align-items: center;align-items: center">
                <div style="color: red;font-size: 20px">*</div> <div style="color: rgba(166, 166, 166, 1);font-size: 12px;">输入商品链接,点击空白处即可更新商品</div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="佣金率：" required prop="commission_rate">
            <div style="display: flex;">
              <el-input :disabled="true" style="width: 200px" v-model.number="form.commission_rate" placeholder="请输入佣金率" clearable></el-input>
              <div style="margin-left: 10px">%</div>
            </div>
          </el-form-item>
          <el-form-item label="任务开始时间：" required prop="task_start_time">
            <el-date-picker
                :disabled="true"
                v-model="form.task_start_time"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="date"
                placeholder="开始时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="任务结束时间：" required prop="task_end_time">
            <el-date-picker
                :disabled="true"
                v-model="form.task_end_time"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="date"
                placeholder="结束时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="排序：" required prop="custom_sort">
            <div style="display: flex;">
              <el-input :disabled="true" style="width: 30%"  v-model.number="form.custom_sort"  clearable></el-input>
              <div style="margin-left: 10px"> <span style="color: rgba(166, 166, 166, 1);font-size: 12px;">数字越大排序越靠前，0为默认排序（按照上架时间倒叙排序）</span></div>
            </div>
          </el-form-item>
          <el-form-item label="任务奖励：" required>
            <el-input :disabled="true" style="width: 160px" v-model="form.quest_rewards" clearable></el-input>
          </el-form-item>
          <el-form-item label="上级经纪人奖励：" required>
            <el-input :disabled="true" style="width: 160px" v-model="form.agent_earnings"  clearable></el-input>
          </el-form-item>
          <el-form-item label="可参与人数" required>
            <el-input :disabled="true" style="width: 160px" v-model.number="form.limit_num"  clearable></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>


    <el-divider></el-divider>
    <div style="margin: 20px">
      视频模板挂载
      <div style="margin: 10px;display: flex">
        <div style="white-space: nowrap;overflow-y:auto;">
          <div style="width: 180px;height: 320px;margin-left: 10px;display: inline-block;" v-for="item in templateProductList" :key="item.id" >
            <el-image
                style="width: 180px;height: 320px;"
                :src="item.mediaProducing.media_id"
                fit="cover">
            </el-image>
            <div style="color: rgba(56, 56, 56, 1);font-size: 14px;margin: 5px">
              <div style="color: rgba(56, 56, 56, 1);font-size: 14px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{item.name}}</div>
              <div style="display: flex;justify-content: space-between;margin-top: 5px">ID：{{ item.template_id }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-divider></el-divider>
    <div style="margin: 20px">
      参与要求
      <div style="margin: 10px;display: flex">
        <el-form :model="form" :rules="rules" ref="ruleFormTwo" :label-position="labelPosition" label-width="160px">
          <el-form-item label="抖音粉丝量" required>
            <div>
              <el-radio :disabled="true" v-model="form.fan_limit" :label="false">不限制</el-radio>
            </div>
            <div>
              <el-radio :disabled="true" v-model="form.fan_limit" :label="true">不低于</el-radio>
              <el-input :disabled="true" size="small" style="width: 160px" v-model.number="form.fan_limit_num"  clearable></el-input>
            </div>
          </el-form-item>
          <el-form-item label="会员限制" required>
            <div>
              <el-radio :disabled="true" v-model="form.member_limit" :label="false">所有人可参与</el-radio>
              <el-radio :disabled="true" v-model="form.member_limit" :label="true">仅限会员参加</el-radio>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <el-divider></el-divider>
    <div style="margin: 20px">
      任务要求
      <div style="margin: 10px;display: flex">
        <el-checkbox-group :disabled="true" v-model="form.task_require" style="display: flex;flex-direction: column">
          <el-checkbox  style="margin: 5px" :label="1">
            使用指定视频模版生成并发布视频至抖音
          </el-checkbox>
          <el-checkbox  style="margin: 5px" :label="2">
            视频发布时需将本商品添加至小黄车
          </el-checkbox>
          <el-checkbox  style="margin: 5px" :label="3">添加指定话题
            <el-input size="small" style="width: 160px"   clearable v-model="form.topic"></el-input>
          </el-checkbox>
          <el-checkbox  style="margin: 5px" :label="4">
            <span>@指定账号<el-input size="small" style="width: 160px;margin-left: 10px" placeholder="请输入账号名称" clearable v-model="form.account"></el-input></span>
            <span style="margin-left: 20px">抖音号:<el-input size="small" style="width: 160px;margin-left: 10px" placeholder="请输入抖音号" clearable v-model="form.account_id"></el-input></span>
          </el-checkbox>
          <el-checkbox  style="margin: 5px" :label="5">视频发布后在
            <el-date-picker
                style="margin-left: 10px"
                v-model="form.ban_del_date"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="date"
                placeholder="请选择日期">
            </el-date-picker>
            前不可删除
          </el-checkbox>
        </el-checkbox-group>
      </div>
      任务详细介绍
      <div style="display: flex;flex-direction: column; align-items: center;justify-content: center">
        <div v-if="form.introduction.length > 0">
          <div style="position: relative;margin-top: 10px" :index="index"  v-for="(item,index) in form.introduction" :key="index">
            <img  :src="item" class="avatar">
          </div>
        </div>
        <div v-else>
          <el-empty description="暂无详细介绍"></el-empty>
        </div>
      </div>

    </div>

    <el-dialog title="添加模板" :visible.sync="addTemplateVisible">
      <el-table
          :data="tableData"
          :loading="listLoading"
          border
          row-key="id"
          ref="multipleTable"
          @selection-change="handleSelectionChange"
          @select="selectOne"
          @select-all="selectAll"
      >
        <el-table-column
            type="selection"
            width="55">
        </el-table-column>
        <el-table-column align="center" prop="id" label="编号" >
        </el-table-column>
        <el-table-column align="center" prop="name" label="模板预览">
          <template slot-scope="scope">
            <div style="position: relative;text-align: center">
              <el-image
                  style="width: 100px;"
                  :src="scope.row.mediaProducing.media_id"
                  lazy>
              </el-image>
            </div>
            <div style="position: absolute;text-align: center; left: 40%;top: 50%;transform: translateX(-40%);transform: translateY(-50%);" @click="preview(scope.row)">
              <i class="el-icon-video-play" style="font-size: 50px;"></i>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="name" label="标题">
        </el-table-column>
        <el-table-column align="center" prop="update_time" label="入库时间">
          <template slot-scope="scope">
            <span v-if="scope.row.update_time != null">{{ parseDateFull(scope.row.update_time) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="buildNum" label="已生成视频总数">
        </el-table-column>
        <el-table-column align="center" prop="dismiss_number" label="模板生成视频数上限">
          <template slot-scope="scope">
            <div v-if="scope.row.dismiss_number==-1">不限制</div>
            <div v-else>{{scope.row.dismiss_number}}</div>
          </template>
        </el-table-column>
      </el-table>
      <el-row style="margin-top: 24px">
        <el-col :span="18" style="text-align: left">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="queryParams.page"
                    :page-size="queryParams.limit"
                    :total="total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
        </el-col>
        <el-col :span="6" style="text-align: right;display: flex;justify-content: right">
          <el-button @click="addTemplateVisible = false">取消</el-button>
          <el-button type="primary" @click="doTemplateProductAdd">添加</el-button>
        </el-col>
      </el-row>
    </el-dialog>

  </div>
</template>

<script>
import { trillProductInfo } from "../../api/trill_goods";
import {templateList} from "../../api/template";
import {getOssAccessInfo} from "../../api/common";
import { grassTaskList ,grassTaskEdit ,grassTaskOne ,grassTaskTemList ,grassTaskTemAdd ,grassTaskTemDel} from "../../api/grass_task";

import Sha1 from "js-sha1";
import {templateProductDel} from "../../api/templateProduct";
var OSS = require("ali-oss");

export default {
  name: "info",
  data(){
    return {
      templateProductList:[],
      selectionIds:[],
      radio:"1",
      queryParams:{
        page: 1,
        limit: 5
      },
      total:0,
      tableData:[],
      addTemplateVisible: false,
      labelPosition: 'right',
      rules:{
        task_start_time: [
          { required: true, message: '请选择任务开始时间', trigger: 'blur' }
        ],
        task_end_time: [
          { required: true, message: '请选择任务结束时间', trigger: 'blur' }
        ]
      },
      form:{
        id: "",
        agent_earnings: 0.00,
        product_id: "",
        custom_sort: 0,
        quest_rewards: 0.00,
        task_start_time: null,
        task_end_time: null,
        commission_rate: 0,
        limit_num: 0,
        template_mount: [],
        fan_limit: false,
        member_limit: false,
        fan_limit_num:0,
        task_require: [],
        topic:"",
        account:"",
        account_id:"",
        ban_del_date:"",
        introduction:[]
      }
    }
  },
  mounted() {
    this.initData(this.$route.query.id);
  },
  methods:{
    doGrassTaskTemList(){
      let qyery = {
        grass_task_id: this.form.id
      }
      grassTaskTemList(qyery).then((response) => {
        this.templateProductList = response.data.records
        this.selectionIds = response.data.records.map(x=>{
          return x.template_id
        })
      });
    },
    initData(id) {
      grassTaskOne({id : id}).then((res) => {
        this.form = res.data
        this.doGrassTaskTemList()
      });
    },
    delMemberItem(index){
      this.form.introduction.splice(index, 1);
    },
    uploadImg(res){
      console.log(res)
      console.log(res.file)
      let newFile = res.file
      getOssAccessInfo().then(res => {
        console.log(JSON.stringify(res))
        const client = new OSS({
          region: "oss-cn-hangzhou",
          accessKeyId: res.data.access_key_id,
          accessKeySecret: res.data.access_key_secret,
          bucket: "vodm-store-prod",
          stsToken: res.data.security_token
        });
        console.log(client)
        let randomStr = "" ;
        for (var i = 0; i < 9; i++) {
          randomStr =randomStr +Math.floor(Math.random() * 10).toString();
        }
        let fileName =  "/merchantscenter/banner/" + Sha1(this.$store.state.token + new Date().getTime() + randomStr) + newFile.type;
        console.log(fileName)
        client.put(fileName, newFile).then(res =>{
          console.log(JSON.stringify(res));
          let url = "https://store.paitk.com/" + fileName;
          console.log(url)
          this.form.introduction.push(url)
          this.$message.success("图片上传成功");
        })
      })
    },
    handleAvatarSuccess(res, file) {
      console.log(JSON.stringify(res))
      console.log(JSON.stringify(file))
    },
    beforeAvatarUpload(file) {
      return true
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initData();
    },
    doTemplateProductAdd(){
      console.log("当前选中selectionIds:",JSON.stringify(this.selectionIds))
      this.form.template_mount = this.selectionIds

      grassTaskTemAdd(this.form).then((res) => {
        if(res.code==0) {
          this.$message.success({
            duration: 1000,
            message: '模板挂载成功'
          });
          this.doGrassTaskTemList()
          this.addTemplateVisible = false
        }else{
          this.$message.error({message: res.message});
        }
      });


    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log("当前选中:",JSON.stringify(val))
    },
    remove(selectionIds,val) {
      let index = selectionIds.indexOf(val);
      if (index > -1) {
        selectionIds.splice(index, 1);
      }
      console.log("remove:",val)
      console.log("selectionIds:",this.selectionIds)
    },
    selectOne(selection, row) {
      let check = null;
      selection.map((item) => {
        if(item.id === row.id){
          check = true;
        }
      })
      if(check){
        console.log("选中:",row.id)
        this.selectionIds.push(row.id)
      }else{
        console.log("取消选中:",row.id)
        this.remove(this.selectionIds,row.id)
      }
    },
    selectAll(selection) {
      let _this = this;
      console.log(selection)
      if (selection.length == 0){
        console.log("取消选择")
        _this.tableData.map((item) => {
          _this.remove(_this.selectionIds,item.id)
        })
      }else {
        console.log("全部选择")
        _this.tableData.forEach((item) => {
          let index = _this.selectionIds.indexOf(item.id);
          if (index > -1) {
            console.log("已经含有啦")
          }else{
            _this.selectionIds.push(item.id)
          }
        });
      }
    },
    selectCheck(){
      let _this = this;
      _this.$nextTick(()=>{
        console.log("_this.$refs",_this.$refs)
        _this.tableData.map((item) => {
          _this.selectionIds.find(value => {
            if(value == item.id){
              _this.$refs.multipleTable.toggleRowSelection(item, true)
            }
          })
        });
      })
    },
    addTemplate(){
      if(this.form.id == null || this.form.id == ""){
        this.$message.error({message: '请先保存商品信息'});
        return
      }
      this.addTemplateVisible = true
      this.initTemData();
    },
    initTemData() {
      this.listLoading = true;
      let queryParams = this.queryParams
      templateList(queryParams).then((response) => {
        this.tableData = response.data.list;
        console.log(JSON.stringify(this.selectionIds))
        this.total = response.data.total_count;
        this.listLoading = false;
        this.selectCheck()
      });
    },
    goBack(){
      this.$router.go(-1);
    },
    doTrillProductInfo(){
      if(this.form.product_url != ''){
        let body = {
          product_url: this.form.product_url
        }
        trillProductInfo({},body).then(res=> {
          if(res.code==0){
            this.$message.success({
              duration: 1000,
              message: '商品信息获取成功'
            });
            this.form.product_id = res.data.productId
          }else{
            this.$message.error({message: '商品链接有误'});
          }
        })
      }
    },
    parseDateFull(time) {
      var x = new Date(time);
      var z = {
        y: x.getFullYear(),
        M: x.getMonth() + 1,
        d: x.getDate(),
        h: x.getHours(),
        m: x.getMinutes(),
        s: x.getSeconds(),
      };
      if (z.M < 10) {
        z.M = "0" + z.M;
      }
      if (z.d < 10) {
        z.d = "0" + z.d;
      }
      if (z.h < 10) {
        z.h = "0" + z.h;
      }
      if (z.m < 10) {
        z.m = "0" + z.m;
      }
      return z.y + "-" + z.M + "-" + z.d + " " + z.h + ":" + z.m ;
    }
  }
}
</script>

<style scoped>

.avatar-uploader{
  border: 1px dashed #757070;
  border-radius: 6px;
  width: 350px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 351px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 351px;
  display: block;
}

.del_item{
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  border: 1px dashed #757070;
  border-radius: 6px;
  position: absolute;
  left:360px; right:0; top:0; bottom:0;
  margin:auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
