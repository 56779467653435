<template>
  <div style="background: white;padding: 10px;margin: 10px">
    <el-tabs v-model="plate" @tab-click="handleClick">
      <el-tab-pane label="抖音板块" name="1"></el-tab-pane>
<!--      <el-tab-pane label="快手板块" name="2"></el-tab-pane>-->
    </el-tabs>

    <div class="item">
      <div>
        <div class="item_name">商品分类</div>
        <div class="item_tips">开启后，用户可以看到商品分类</div>
      </div>
      <el-switch
          @change="doSaveConf"
          v-model="config.classify_show"
          active-color="#13ce66"
          inactive-color="#ff4949">
      </el-switch>
    </div>
    <el-divider></el-divider>

    <div class="item">
      <div>
        <div class="item_name">全部商品</div>
        <div class="item_tips">开启后，用户可以看到全部商品的分类（前提条件：开启商品分类）</div>
      </div>
      <el-switch
          @change="doSaveConf"
          v-model="config.classify_all"
          active-color="#13ce66"
          inactive-color="#ff4949">
      </el-switch>
    </div>
    <el-divider></el-divider>


    <div class="item">
      <div>
        <div class="item_name">分类icon图片</div>
        <div class="item_tips">开启后，用户可以看到商品分类的icon图片</div>
      </div>
      <el-switch
          @change="doSaveConf"
          v-model="config.classify_icon_show"
          active-color="#13ce66"
          inactive-color="#ff4949">
      </el-switch>
    </div>
    <el-divider></el-divider>


    <el-button size="small" type="primary" @click="dialogAdd">新增分类</el-button>
    <el-table
        border
        size="small"
        :data="tableData"
        style="width: 100%;margin-top: 10px">
      <el-table-column
          align="center"
          prop="merchant_id"
          label="来源">
        <template slot-scope="scope">
          <span v-if="scope.row.merchant_id === -1">运营中心</span>
          <span v-else>自建</span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="classify_name"
          label="分类名称">
      </el-table-column>
      <el-table-column
          align="center"
          prop="classify_icon"
          label="分类icon照片">
        <template slot-scope="scope">
          <img
              style="width: 50px;height: 50px;border-radius: 50%"
              :src="scope.row.classify_icon"
              fit="cover"/>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="classify_sort"
          label="排序">
      </el-table-column>
      <el-table-column
          align="center"
          prop="classify_show"
          label="是否显示">
        <template slot-scope="scope">
            <span v-if="scope.row.classify_show === true">是</span>
            <span v-else>否</span>
        </template>
      </el-table-column>
      <el-table-column
          width="180"
          align="center"
          prop="create_time"
          label="创建时间">
      </el-table-column>
      <el-table-column
          align="center"
          prop="product_num"
          label="分类商品数">
      </el-table-column>
      <el-table-column align="center" label="操作" >
        <template slot-scope="scope">
          <div v-if="scope.row.merchant_id === -1">
              -
          </div>
          <div v-else>
            <el-button type="text" size="small" @click="dialogEdit(scope.row)">
              编辑
            </el-button>
            <el-button v-if="scope.row.product_num <= 0" type="text" size="small" @click="dialogDel(scope.row)">
              删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-row style="margin-top: 24px;margin-right: 20px">
      <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="queryParams.page"
                    :page-size="queryParams.limit"
                    :total="total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
      </el-col>
    </el-row>


    <el-dialog
        size="small"
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        width="40%">

      <el-form ref="form" :model="form" label-width="120px">
        <el-form-item label="分类icon图片：">

          <el-upload
              class="avatar-uploader"
              action=""
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              :http-request="uploadImg"
          >
            <img v-if="form.classify_icon" :src="form.classify_icon" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <span style="color: rgba(166, 166, 166, 1);font-size: 12px;">建议上传尺寸为200*200px的jpg、png图片</span>


        </el-form-item>
        <el-form-item label="分类名称：">
          <el-input v-model="form.classify_name"></el-input>
        </el-form-item>
        <el-form-item label="排序：">
          <el-input v-model="form.classify_sort"></el-input>
          <span style="color: rgba(166, 166, 166, 1);font-size: 12px;">数字越大排序越靠前，0为默认排序（按照创建时间倒叙排序）</span>
        </el-form-item>
        <el-form-item label="是否显示：">
          <el-radio-group v-model="form.classify_show">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>

      </el-form>


      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="update">
          {{dialogTitle}}
        </el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import {getConf, saveConf, queryPage, update, del, queryUsePage} from "../../api/product_classify.js";
import {getOssAccessInfo} from "../../api/common";
import Sha1 from "js-sha1";
var OSS = require("ali-oss");
export default {
  name: "classify",
  data(){
    return{
      dialogTitle:"",
      form:{
        classify_name: "",
        classify_icon: "",
        classify_sort: 0,
        classify_show: true,
        plate: null
      },
      dialogVisible: false,
      config:{
        classify_show:true,
        classify_all:true,
        classify_icon_show:true
      },
      plate:"1",
      queryParams:{
        plate: null,
        page: 1,
        limit: 10,
        sidx: "create_time",
        order: "desc"
      },
      total:0,
      listLoading:false,
      tableData: []
    }
  },
  mounted(){
    this.initData()
  },
  methods:{
    handleAvatarSuccess(res, file) {
      console.log(JSON.stringify(res))
      console.log(JSON.stringify(file))
    },
    beforeAvatarUpload(file) {
      return true
    },
    uploadImg(res){
      console.log(res)
      console.log(res.file)
      let newFile = res.file
      getOssAccessInfo().then(res => {
        console.log(JSON.stringify(res))
        const client = new OSS({
          region: "oss-cn-hangzhou",
          accessKeyId: res.data.access_key_id,
          accessKeySecret: res.data.access_key_secret,
          bucket: "vodm-store-prod",
          stsToken: res.data.security_token
        });
        console.log(client)
        let randomStr = "" ;
        for (var i = 0; i < 9; i++) {
          randomStr =randomStr +Math.floor(Math.random() * 10).toString();
        }
        let fileName =  "/merchantscenter/classify/" + Sha1(this.$store.state.token + new Date().getTime() + randomStr) + newFile.type;
        console.log(fileName)
        client.put(fileName, newFile).then(res =>{
          console.log(JSON.stringify(res));
          this.form.classify_icon = "https://store.paitk.com/" + fileName;
          console.log(JSON.stringify(this.bannerForm))
          this.$message.success("上传成功");
        })
      })
    },
    update(){
      update(this.form).then((res) => {
        if(res.code==0) {
          this.$message.success(this.dialogTitle + "成功");
          this.initData();
          this.dialogVisible = false
        }else{
          this.$message.error({message: res.message});
        }
      });
    },
    dialogDel(row){
      this.$confirm("确认删除该分类?", "删除分类", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        del(row).then((res) => {
          if(res.code==0) {
            this.$message.success("删除分类成功");
            this.initData();
          }else{
            this.$message.error({message: res.message});
          }
        });
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
    },
    dialogAdd(){
      this.dialogTitle = "新增分类"
      this.form = {
        plate: this.plate,
        classify_show: true,
        classify_sort: 0,
        classify_icon: "",
      }
      this.dialogVisible = true
    },
    dialogEdit(row){
      this.dialogTitle = "修改分类"
      this.form = row
      this.dialogVisible = true
    },
    handleClick(){
      this.initData()
    },
    doSaveConf(){
      saveConf(this.config).then((res) => {
        if(res.code==0) {
          this.$message.success("操作成功");
          this.initData();
        }else{
          this.$message.error({message: res.message});
        }
      });
    },
    doGetConf(){
      getConf(this.queryParams).then((res) => {
        this.config = res.data;
      });
    },
    doQueryPage(){
      this.listLoading = true
      queryUsePage(this.queryParams).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total_count;
        this.listLoading = false;
      });
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    initData(){
      this.queryParams.plate = this.plate
      this.doGetConf()
      this.doQueryPage()
    },
  }
}
</script>

<style scoped>

.item{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item_name{
  font-size: 16px;
  font-weight: 500;
  color: rgba(56, 56, 56, 1);
}

.item_tips{
  margin-top: 5px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(56, 56, 56, 1);
}


.avatar-uploader{
  border: 1px dashed #757070;
  border-radius: 6px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 90px;
  height: 90px;
  display: block;
}
</style>
