<template>
  <div class="content" style="background: white; padding: 10px; margin: 10px">
    <div style="font-size: 20px; font-weight: bold; text-align: left">
      渠道合作伙伴
    </div>

    <div class="bus_btn">
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-plus"
        size="small"
        @click="handleAdd"
        >新增渠道合作伙伴</el-button
      >
    </div>

    <div style="margin-top: 10px; text-align: left">
      <el-input
        size="small"
        placeholder="请输入渠道合作伙伴编号"
        v-model="queryParams.id"
        clearable
        style="width: 180px"
      >
      </el-input>
      <el-button
        style="margin-left: 10px"
        type="primary"
        icon="el-icon-search"
        size="small"
        @click="doSearch()"
        >搜索</el-button
      >
    </div>

    <div class="table_view">
      <el-table
        :data="tableData"
        :loading="listLoading"
        border
        :max-height="autoHeight"
      >
        <el-table-column align="center" prop="id" label="ID"> </el-table-column>
        <el-table-column align="center" prop="user_phone" label="手机号">
        </el-table-column>
        <el-table-column align="center" prop="user_name" label="账户名">
        </el-table-column>
        <el-table-column align="center" prop="user_avatar" label="头像">
          <template slot-scope="scope">
            <el-image
              style="width: 100px"
              :src="scope.row.user_avatar"
              :preview-src-list="getSrcList(scope.row.user_avatar)"
              lazy
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="com_ratio" label="分成比例">
        </el-table-column>

        <el-table-column align="center" prop="balance" label="佣金余额账户">
        </el-table-column>
        <el-table-column align="center" prop="sxt_balance" label="投放账户余额">
        </el-table-column>

        <el-table-column align="center" prop="create_time" label="创建时间">
        </el-table-column>
        <el-table-column align="center" prop="create_time" label="更新时间">
        </el-table-column>

        <el-table-column align="center" fixed="right" label="操作" width="160">
          <template slot-scope="scope">
            <el-button @click="handleUpdate(scope.row)" type="text" size="small"
              >修改</el-button
            >
            <el-button type="text" size="small" @click="blanceInfo(scope.row)"
              >余额管理</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="putAccountManage(scope.row)"
              >投放账户管理</el-button
            >
            <el-button type="text" size="small" @click="dkRatio(scope.row)"
              >抖客运营配置</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-row style="margin-top: 24px">
      <el-col :span="24" style="text-align: right">
        <span class="page-tool">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="queryParams.page"
            :page-size="queryParams.limit"
            :total="total"
            layout="total, prev, pager, next"
          ></el-pagination>
        </span>
      </el-col>
    </el-row>

    <el-dialog :title="title" :visible.sync="open" width="60%">
      <el-form
        size="small"
        ref="form"
        :model="form"
        label-width="140px"
        style="padding-right: 20px"
      >
        <el-form-item label="伙伴头像">
          <el-upload
            class="avatar-uploader"
            action=""
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :http-request="uploadImg"
          >
            <img
              v-if="form.user_avatar"
              :src="form.user_avatar"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="伙伴名称" prop="name">
          <el-input
            v-model="form.user_name"
            clearable
            placeholder="请输入伙伴名称"
          />
        </el-form-item>
        <el-form-item label="登录手机号" prop="name">
          <el-input
            :disabled="form.id"
            v-model="form.user_phone"
            clearable
            placeholder="请输入登录手机号"
          />
        </el-form-item>
        <el-form-item label="登录密码" prop="name">
          <el-input
            v-model="form.user_pass"
            clearable
            placeholder="请输入登录密码"
          />
        </el-form-item>
        <el-form-item label="分成比例" prop="com_ratio">
          <el-input-number
            style="width: 100%"
            :controls="false"
            :precision="2"
            :min="0"
            :max="100"
            v-model="form.com_ratio"
            placeholder="请输入分成比例"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="APP分成比例" prop="channel_ratio">
          <el-input-number
            style="width: 100%"
            :controls="false"
            :precision="2"
            :min="0"
            :max="100"
            v-model="form.channel_ratio"
            placeholder="请输入APP分成比例"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="APP分成比例升级" prop="channel_ratio">
          <el-input-number
            style="width: 100%"
            :controls="false"
            :precision="2"
            :min="0"
            :max="100"
            v-model="form.channel_ratio_up"
            placeholder="请输入APP分成比例升级"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="企微客服url" prop="wx_customer">
          <el-input
            v-model="form.wx_customer"
            clearable
            placeholder="请输入企微客服url"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button v-if="form.id" type="primary" @click="updateForm"
          >修 改</el-button
        >
        <el-button v-else type="primary" @click="submitForm">新 增</el-button>
        <el-button @click="open = false">取 消</el-button>
      </div>
    </el-dialog>

    <el-dialog
      v-if="balanceVisible"
      :title="blanceInfoTitle"
      :visible.sync="balanceVisible"
      width="60%"
    >
      <ChannelUserBlanceRes :userInfo="userInfo"></ChannelUserBlanceRes>
    </el-dialog>

    <el-dialog
      title="投放账户余额管理"
      :visible.sync="showPutDialog"
      width="60%"
    >
      <PutAccountSxtBlance :userInfo="userInfo"></PutAccountSxtBlance>
    </el-dialog>

    <el-dialog title="抖客运营配置" :visible.sync="dkRatioVisible" width="40%">
      <el-radio-group v-model="dkForm.ratioInfo.lever_source">
        <el-radio :label="1">跟随系统</el-radio>
        <el-radio :label="2">
          自定义推广等级
          <el-select
            style="margin-top: 20px; width: 140px"
            v-model="dkForm.ratioInfo.user_lever"
            placeholder="选择推广等级"
            size="small"
          >
            <el-option
              v-for="item in dkForm.ratioBase"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-radio>
      </el-radio-group>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dkRatioVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="dkEdit()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { dkRatioinfo, dkRatioUpdate } from "../../api/sklmUser";

import { channelList, add, update } from "../../api/channel_user";
import { getOssAccessInfo } from "../../api/common";
import Sha1 from "js-sha1";
var OSS = require("ali-oss");

import ChannelUserBlanceRes from "./ChannelUserBlanceRes.vue";
import PutAccountSxtBlance from "./putAccountSxtBlance.vue";

export default {
  name: "index",
  components: {
    ChannelUserBlanceRes,
    PutAccountSxtBlance,
  },
  data() {
    return {
      dkRatioVisible: false,
      dkForm: {
        ratioInfo: {
          lever_source: 1,
        },
        ratioBase: [],
      },

      dkInfo: {
        userId: "",
        userType: "CHA", //用户: USER 经纪人:TEAM 合伙人:CHA
      },

      autoHeight: "",
      autoWidth: "",
      queryParams: {
        page: 1,
        limit: 10,
        sidx: "create_time",
        order: "desc",
      },
      tableData: [],
      total: 0,
      listLoading: false,
      open: false,
      title: "",
      form: {},
      balanceVisible: false,
      blanceInfoTitle: "",
      userInfo: {},
      putAccountobj: {}, // 投放账户信息
      showPutDialog: false, // 余额管理弹窗
    };
  },
  mounted() {
    this.getHeight();
    this.initData();
  },
  methods: {
    dkRatio(row) {
      this.dkRatioVisible = true;
      // console.log("dk id", row);
      this.dkInfo.userId = row.id;
      // console.log("this.dkInfo",this.dkInfo);
      this.getDkRatioinfo();
    },
    getDkRatioinfo() {
      dkRatioinfo(this.dkInfo).then((res) => {
        console.log("抖客", res);
        this.dkForm = res.data;
      });
    },

    dkEdit() {
      console.log("抖客提交", this.dkForm.ratioInfo);

      dkRatioUpdate(this.dkForm.ratioInfo).then((res) => {
        if (res.code == 0) {
          this.$message.success("保存成功");
          this.dkRatioVisible = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 投放账户管理
    putAccountManage(data) {
      this.putAccountobj = data;
      this.showPutDialog = true;
    },
    blanceInfo(row) {
      console.log(JSON.stringify(row));
      this.userInfo = JSON.parse(JSON.stringify(row));
      this.blanceInfoTitle = "余额管理-" + row.user_name;
      this.balanceVisible = true;
    },
    handleAvatarSuccess(res, file) {
      console.log(JSON.stringify(res));
      console.log(JSON.stringify(file));
    },
    beforeAvatarUpload(file) {
      return true;
    },
    uploadImg(res) {
      console.log(res);
      console.log(res.file);
      let newFile = res.file;
      getOssAccessInfo().then((res) => {
        console.log(JSON.stringify(res));
        const client = new OSS({
          region: "oss-cn-hangzhou",
          accessKeyId: res.data.access_key_id,
          accessKeySecret: res.data.access_key_secret,
          bucket: "vodm-store-prod",
          stsToken: res.data.security_token,
        });
        console.log(client);
        let randomStr = "";
        for (var i = 0; i < 9; i++) {
          randomStr = randomStr + Math.floor(Math.random() * 10).toString();
        }
        let fileName =
          "/merchants/channel_user/user_avatar" +
          Sha1(this.$store.state.token + new Date().getTime() + randomStr) +
          newFile.type;
        console.log(fileName);
        client.put(fileName, newFile).then((res) => {
          console.log(JSON.stringify(res));
          this.form.user_avatar = "https://store.paitk.com/" + fileName;
          this.$message.success("头像上传成功");
        });
      });
    },
    getHeight() {
      this.autoHeight = parseInt(window.innerHeight) - 250 + "px";
      this.autoWidth = parseInt(window.innerWidth) + "px";
    },
    handleAdd() {
      this.form = {
        user_phone: "",
        user_pass: "",
        user_name: "",
        user_avatar: "",
      };
      this.title = "新增伙伴";
      this.open = true;
    },
    handleUpdate(row) {
      this.form = JSON.parse(JSON.stringify(row));
      this.title = "修改伙伴";
      this.open = true;
    },
    initData() {
      this.listLoading = true;
      channelList(this.queryParams).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total_count;
        this.listLoading = false;
      });
    },
    doSearch() {
      this.queryParams.page = 1;
      this.initData();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    getSrcList(url) {
      let imgList = [];
      imgList.push(url);
      return imgList;
    },
    submitForm() {
      add(this.form).then((res) => {
        if (res.status == 1) {
          this.form = {};
          this.initData();
          this.$message.success("新增成功");
          this.open = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    updateForm() {
      update(this.form).then((res) => {
        if (res.status == 1) {
          this.form = {};
          this.initData();
          this.$message.success("修改成功");
          this.open = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>

<style scoped>
.bus_btn {
  margin-top: 10px;
  width: 100%;
  text-align: left;
}

.table_view {
  margin-top: 10px;
  width: 100%;
}

.avatar-uploader {
  border: 1px dashed #757070;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.form_item_row {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.item_row_left {
  width: 100px;
}

.item_row_end {
  margin-left: 5px;
}

.el-radio-group {
  display: flex;
  flex-direction: column;
}
</style>
