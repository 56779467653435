<template>
  <div class="content" style="background: white;padding: 10px;margin: 10px">

    <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-plus"
        size="mini"
        @click="add"
    >新增海报</el-button>

    <el-table
        :data="tableData"
        :max-height="autoHeight"
        :loading="listLoading"
        border
        style="margin-top: 10px;width: 100%;"
    >
      <el-table-column align="center" prop="image_background" label="海报底图">
        <template slot-scope="scope">
          <el-image
              style="width: 50px;"
              :src="scope.row.image_background"
              :preview-src-list="getSrcList(scope.row.image_background)"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="custom_sort" label="排序">
      </el-table-column>
      <el-table-column align="center" prop="source_miniprogram" label="是否显示">
        <template slot-scope="scope">
          <el-switch
              disabled
              v-model="scope.row.is_show"
              active-color="#13ce66"
              inactive-color="#ff4949">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="create_time" label="创建时间">
      </el-table-column>
      <el-table-column align="center" label="操作" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="preview(scope.row)">预览</el-button>
          <el-button type="text" size="small" @click="edit(scope.row)">编辑</el-button>
          <el-button type="text" size="small" @click="del(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>



    <el-dialog :title="title" :visible.sync="open" width="60%">
      <el-form size="small" :rules="rules" ref="ruleForm" :model="form"  label-width="120px">
        <div style="display: flex;justify-content: center;align-items: center">
          <div>
            <el-form-item label="基础底图">
              <el-upload
                  class="avatar-uploader"
                  action=""
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                  :http-request="uploadImg"
              >
                <img v-if="form.image_background" :src="form.image_background"  class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="预览">
                <el-image
                    style="width: 200px;"
                    :src="form.posters_url"
                    :preview-src-list="getSrcList(form.posters_url)"
                ></el-image>
            </el-form-item>
          </div>

          <div style="margin-left: 30px">
            <span class="head_title">基础设置</span>
            <el-form-item label="排序:" prop="custom_sort">
              <el-input-number
                  style="width: 200px"
                  :controls="false" :precision="0" :min="0"
                  v-model="form.custom_sort" placeholder="请输入排序"></el-input-number>
            </el-form-item>
            <el-form-item label="是否显示:" prop="is_show">
              <el-switch
                  v-model="form.is_show"
                  active-color="#13ce66"
                  inactive-color="#ff4949">
              </el-switch>
            </el-form-item>
            <span class="head_title">二维码配置</span>
            <el-form-item label="二维码大小:" prop="size_user">
              <el-input-number
                  style="width: 200px"
                  :controls="false" :precision="0" :min="0"
                  v-model="form.size_user" placeholder="请输入二维码大小"></el-input-number>
              <span style="margin-left: 5px">px</span>
            </el-form-item>
            <el-form-item label="位置(X轴):" prop="xuser">
              <el-input-number
                  style="width: 200px"
                  :controls="false" :precision="0" :min="0"
                  v-model="form.xuser" placeholder="请输入位置(X轴)"></el-input-number>
              <span style="margin-left: 5px">px</span>
            </el-form-item>
            <el-form-item label="位置(Y轴):" prop="yuser">
              <el-input-number
                  style="width: 200px"
                  :controls="false" :precision="0" :min="0"
                  v-model="form.yuser" placeholder="请输入位置(Y轴)"></el-input-number>
              <span style="margin-left: 5px">px</span>
            </el-form-item>

            <span class="head_title">用户头像配置</span>
            <el-form-item label="头像大小:" prop="size_head_pic">
              <el-input-number
                  style="width: 200px"
                  :controls="false" :precision="0" :min="0"
                  v-model="form.size_head_pic" placeholder="请输入头像大小"></el-input-number>
              <span style="margin-left: 5px">px</span>
            </el-form-item>
            <el-form-item label="位置(X轴):" prop="xhead_pic">
              <el-input-number
                  style="width: 200px"
                  :controls="false" :precision="0" :min="0"
                  v-model="form.xhead_pic" placeholder="请输入位置(X轴)"></el-input-number>
              <span style="margin-left: 5px">px</span>
            </el-form-item>
            <el-form-item label="位置(Y轴):" prop="yhead_pic">
              <el-input-number
                  style="width: 200px"
                  :controls="false" :precision="0" :min="0"
                  v-model="form.yhead_pic" placeholder="请输入位置(Y轴)"></el-input-number>
              <span style="margin-left: 5px">px</span>
            </el-form-item>

            <span class="head_title">微信昵称配置</span>
            <el-form-item label="昵称大小:" prop="size_nick_name">
              <el-input-number
                  style="width: 200px"
                  :controls="false" :precision="0" :min="0"
                  v-model="form.size_nick_name" placeholder="请输入昵称大小"></el-input-number>
              <span style="margin-left: 5px">px</span>
            </el-form-item>
            <el-form-item label="位置(X轴):" prop="xnick_name">
              <el-input-number
                  style="width: 200px"
                  :controls="false" :precision="0" :min="0"
                  v-model="form.xnick_name" placeholder="请输入位置(X轴)"></el-input-number>
              <span style="margin-left: 5px">px</span>
            </el-form-item>
            <el-form-item label="位置(Y轴):" prop="ynick_name">
              <el-input-number
                  style="width: 200px"
                  :controls="false" :precision="0" :min="0"
                  v-model="form.ynick_name" placeholder="请输入位置(Y轴)"></el-input-number>
              <span style="margin-left: 5px">px</span>
            </el-form-item>
            <el-form-item label="颜色:" prop="nick_name_color">
              <el-color-picker v-model="form.nick_name_color" @change="colorChange"></el-color-picker>
            </el-form-item>

            <span class="head_title">邀请码配置</span>
            <el-form-item label="邀请码大小:" prop="size_invi_code">
              <el-input-number
                  style="width: 200px"
                  :controls="false" :precision="0" :min="0"
                  v-model="form.size_invi_code" placeholder="请输入昵称大小"></el-input-number>
              <span style="margin-left: 5px">px</span>
            </el-form-item>
            <el-form-item label="位置(X轴):" prop="xinvi_code">
              <el-input-number
                  style="width: 200px"
                  :controls="false" :precision="0" :min="0"
                  v-model="form.xinvi_code" placeholder="请输入位置(X轴)"></el-input-number>
              <span style="margin-left: 5px">px</span>
            </el-form-item>
            <el-form-item label="位置(Y轴):" prop="yinvi_code">
              <el-input-number
                  style="width: 200px"
                  :controls="false" :precision="0" :min="0"
                  v-model="form.yinvi_code" placeholder="请输入位置(Y轴)"></el-input-number>
              <span style="margin-left: 5px">px</span>
            </el-form-item>
            <el-form-item label="颜色:" prop="invi_code_color">
              <el-color-picker v-model="form.invi_code_color" @change="colorChange"></el-color-picker>
            </el-form-item>

          </div>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="subitForm('ruleForm')">保存预览</el-button>
        <el-button @click="open=false">取消</el-button>
      </div>
    </el-dialog>


    <el-dialog title="预览" :visible.sync="previewVisible"
               width="30%" style="text-align: center">
      <el-image
          style="width: 200px;"
          :src="previewUrl"
          :preview-src-list="getSrcList(previewUrl)"
      ></el-image>
    </el-dialog>

  </div>
</template>

<script>
import { postersList ,saveOrUpdate ,postersDel } from "../../api/posters";
import {getOssAccessInfo} from "../../api/common";

import Sha1 from "js-sha1";
var OSS = require("ali-oss");

export default {
  name: "index",
  data(){
    return{
      previewVisible: false,
      previewUrl: '',
      open:false,
      title:'',
      form:{
        image_background: '',
        posters_url: ''
      },
      rules:{
        custom_sort: [
          { required: true, message: '', trigger: 'blur' }
        ],
        size_user: [
          { required: true, message: '', trigger: 'blur' }
        ],
        size_head_pic: [
          { required: true, message: '', trigger: 'blur' }
        ],
        size_nick_name: [
          { required: true, message: '', trigger: 'blur' }
        ],
        nick_name_color: [
          { required: true, message: '', trigger: 'blur' }
        ],
        xhead_pic: [
          { required: true, message: '', trigger: 'blur' }
        ],
        yhead_pic: [
          { required: true, message: '', trigger: 'blur' }
        ],
        xnick_name: [
          { required: true, message: '', trigger: 'blur' }
        ],
        ynick_name: [
          { required: true, message: '', trigger: 'blur' }
        ],
        xuser: [
          { required: true, message: '', trigger: 'blur' }
        ],
        yuser: [
          { required: true, message: '', trigger: 'blur' }
        ]
      },
      getSrcList(src){
        let imgList = [];
        imgList.push(src);
        return imgList;
      },
      autoHeight:'',
      autoWidth:'',
      listLoading:false,
      tableData: []
    }
  },
  created() {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  mounted(){
    this.initData()
  },
  methods: {
    del(row){
      this.$confirm("确认删除?", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        console.log(JSON.stringify(row))
        postersDel({ id: row.posters_id }).then((res) => {
          if(res.status == 1){
            this.initData();
            this.$message.success("删除成功");
          }else {
            this.$message.error(res.message);
          }
        });
      }).catch(() => {});
    },
    preview(row){
      let previewUrl = row.posters_url + "&timeStamp=" + new Date()
      this.previewUrl = previewUrl
      console.log(this.previewUrl)
      this.previewVisible = true
    },
    subitForm(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          saveOrUpdate(this.form).then((res) => {
            if(res.status == 1){
              console.log(res.data)
              this.form = res.data
              this.form.posters_url = this.form.posters_url + "&timeStamp=" + new Date()
              console.log(this.form)
              this.initData()
              this.$message.success("操作成功")
            }else {
              this.$message.error(res.message);
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    colorChange(val){
      console.log("colorChange:" + val)
    },
    add(){
      this.title = "新增";
      this.form = {
        image_background: ''
      }
      this.open = true;
    },
    edit(row){
      this.title = "修改";
      this.form = JSON.parse(JSON.stringify(row))
      console.log(JSON.stringify(this.form))
      this.open = true;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handleAvatarSuccess(res, file) {
      console.log(JSON.stringify(res))
      console.log(JSON.stringify(file))
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type === "image/jpeg";
      // const isLt2M = file.size / 1024 / 1024 < 2;
      // if (!isJPG) {
      //   this.$message.error("上传头像图片只能是 JPG 格式!");
      // }
      // if (!isLt2M) {
      //   this.$message.error("上传头像图片大小不能超过 2MB!");
      // }
      // return isJPG && isLt2M;
      return true
    },
    uploadImg(res){
      console.log(res)
      console.log(res.file)
      let newFile = res.file
      getOssAccessInfo().then(res => {
        console.log(JSON.stringify(res))
        const client = new OSS({
          region: "oss-cn-hangzhou",
          accessKeyId: res.data.access_key_id,
          accessKeySecret: res.data.access_key_secret,
          bucket: "vodm-store-prod",
          stsToken: res.data.security_token
        });
        console.log(client)
        let randomStr = "" ;
        for (var i = 0; i < 9; i++) {
          randomStr =randomStr +Math.floor(Math.random() * 10).toString();
        }
        let fileName =  "/operationcenter/posters/" + Sha1(this.$store.state.token + new Date().getTime() + randomStr) + newFile.type;
        console.log(fileName)
        client.put(fileName, newFile).then(res =>{
          console.log(JSON.stringify(res));
          this.form.image_background = "https://store.paitk.com/" + fileName;
          console.log(JSON.stringify(this.form))
          this.$message.success("上传成功");
        })
      })
    },
    getHeight() {
      this.autoHeight = (parseInt(window.innerHeight) - 250) + 'px';
      this.autoWidth = (parseInt(window.innerWidth)) + 'px';
    },
    initData() {
      this.listLoading = true;
      let queryParams = {
        page:1,
        limit:999
      }
      postersList(queryParams).then((res) => {
        this.tableData = res.data.list;
        this.listLoading = false;
      });
    }
  }
}
</script>

<style scoped>

.content {
  padding: 20px;
}


.avatar-uploader{
  border: 1px dashed #757070;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 200px;
  min-height: 200px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  min-height: 178px;
  height: auto;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 100%;
  height: auto;
  display: block;
}

.head_title{
  font-size: 14px;
  font-weight: 500;
}
</style>
