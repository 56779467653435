<template>
  <div style="background: white; padding: 10px; margin: 10px">
    <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      修改Banner
      <div>
        <el-button size="small" style="margin-top: 10px" @click="goBack"
          >取消</el-button
        >
        <el-button
          size="small"
          style="margin-top: 10px"
          type="primary"
          @click="add()"
          >修改</el-button
        >
      </div>
    </div>
    <el-form
      style="margin-top: 20px"
      :label-position="labelPosition"
      label-width="160px"
    >
      <el-form-item label="Banner图片: ">
        <el-upload
          class="avatar-uploader"
          action=""
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
          :http-request="uploadImg"
        >
          <img
            v-if="bannerForm.img_url"
            :src="bannerForm.img_url"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <span style="color: rgba(166, 166, 166, 1); font-size: 12px"
          >建议上传尺寸为750*328px的jpg、png图片</span
        >
      </el-form-item>
      <el-form-item label="页面名称: ">
        <el-input
          style="width: 200px"
          v-model="bannerForm.page_name"
          placeholder="请输入专题页页面名称"
          clearable
        ></el-input>
      </el-form-item>

      <el-form-item label="所属平台:">
        <el-radio-group v-model="bannerForm.platform">
          <el-radio :label="1">小程序</el-radio>
          <el-radio :label="2">APP</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="排序: ">
        <div style="display: flex">
          <el-input
            style="width: 200px"
            v-model.number="bannerForm.custom_sort"
            placeholder="请输入排序"
            clearable
          ></el-input>
          <div style="margin-left: 10px">
            <span style="color: rgba(166, 166, 166, 1); font-size: 12px"
              >数字越大排序越靠前，0为默认排序（按照上架时间倒叙排序）</span
            >
          </div>
        </div>
      </el-form-item>
      <el-form-item label="是否显示: ">
        <el-switch
          v-model="bannerForm.whether_show"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </el-form-item>

      <el-form-item label="banner类型:">
        <el-radio-group v-model="bannerForm.banner_type" @input="roterTyp()">
          <el-radio :label="1">富文本</el-radio>
          <el-radio :label="2">外链</el-radio>
          <!-- <el-radio :label="2">跳转h5</el-radio> -->
          <el-radio :label="3">app内部导航</el-radio>
        </el-radio-group>
      </el-form-item>

      <div class="flex-left" v-if="bannerForm.banner_type == 3">
        <el-form-item label="选择路由: ">
          <div class="flex-left">
            <el-select
              @change="routerId"
              v-model="bannerForm.con_id"
              placeholder="请选择路由"
            >
              <el-option
                v-for="item in rouerList"
                :key="item.con_id"
                :label="item.title"
                :value="item.con_id"
              >
              </el-option>
            </el-select>
          </div>
        </el-form-item>
      </div>

      <div
        class="flex-left k-m-l-30 k-m-t"
        v-for="(rotItem, rotInd) in routeFilter[0].argument.domains"
        :key="rotInd"
      >
        <div style="width: 150px">
          参数配置{{ rotInd + 1 }}：{{ rotItem.name }}
        </div>
        <el-input
          style="width: 200px"
          v-model.number="bannerForm.argument.domains[rotInd].value"
          placeholder="请输入value"
          clearable
        ></el-input>
      </div>

      <el-form-item label="外链:" v-show="bannerForm.banner_type == 2">
        <el-input
          v-model="bannerForm.route_h5"
          placeholder="请输入外链"
          clearable
        ></el-input>
      </el-form-item>

      <!-- <el-form-item label="H5链接:" v-show="bannerForm.banner_type === 2">
        <el-input
          v-model="bannerForm.content"
          placeholder="请输入H5链接"
          clearable
        ></el-input>
      </el-form-item>

      <el-form-item label="APP路由:" v-show="bannerForm.banner_type === 3">
        <el-input
          v-model="bannerForm.content"
          placeholder="请输入APP路由"
          clearable
        ></el-input>
      </el-form-item> -->
    </el-form>

    <div v-show="bannerForm.banner_type === 1">
      <el-divider></el-divider>
      专题页内容
      <div
        ref="editor"
        @input="editorChange"
        style="margin-top: 20px; width: 100%"
      />
    </div>
  </div>
</template>

<script>
import { getOssAccessInfo } from "../../api/common.js";
import { webContListApi } from "@/api/marketing.js";
import Sha1 from "js-sha1";
var OSS = require("ali-oss");

import E from "wangeditor";
import {
  bannerInfo,
  bannerList,
  bannerSaveOrUpdate,
} from "../../api/marketing";

export default {
  name: "editBanner",
  data() {
    return {
      labelPosition: "right",
      bannerForm: {
        img_url: "",
        banner_type: "",
        argument: {
          domains: [],
        },
      },
      htmlContent: "",
      rouerList: [],
      routeFilter: [
        {
          argument: {
            domains: [],
          },
        },
      ],
    };
  },
  mounted() {},

  created() {
    this.initData(this.$route.query.id);
    this.webContList();
    this.roterTyp();
    console.log(this.bannerForm);
  },
  methods: {
    // 请选择路由
    routerId() {
      // (this.bannerForm.ind = ""), (this.bannerForm.key = "");
      let newId = this.bannerForm.con_id;
      console.log(this.rouerList);
      this.routeFilter = this.rouerList.filter((item) => item.con_id === newId);
      this.bannerForm.argument.domains = this.routeFilter[0].argument.domains;
      console.log(this.routeFilter);
    },

    roterTyp() {
      this.bannerForm.con_id = "";
      this.bannerForm.content = "";
      this.routeFilter[0].argument.domains = [];
      this.bannerForm.argument.domains = [];

      if (this.bannerForm.banner_type == 3) {
        this.bannerForm.routeType = 1;
      } else {
        this.bannerForm.routeType = "";
      }
      this.webContList();
    },

    async webContList() {
      const res = await webContListApi();
      this.rouerList = res.data.list;
    },

    initData(id) {
      console.log("initData");
      let _this = this;
      bannerInfo({ id: id }).then((response) => {
        _this.bannerForm = response.data;
        _this.routeFilter[0].argument.domains = response.data.argument.domains;

        if (response.data.banner_type == 3) {
          _this.bannerForm.routeType = 1;
        }

        console.log(_this.bannerForm);

        this.webContList();

        console.log(response.data.argument.domains);
        var editor = new E(_this.$refs.editor);
        editor.config.zIndex = 10;
        editor.config.customUploadImg = function (files, insert) {
          files.forEach((image) => {
            getOssAccessInfo().then((res) => {
              const client = new OSS({
                region: "oss-cn-hangzhou",
                accessKeyId: res.data.access_key_id,
                accessKeySecret: res.data.access_key_secret,
                bucket: "vodm-store-prod",
                stsToken: res.data.security_token,
              });
              console.log(client);
              let randomStr = "";
              for (var i = 0; i < 9; i++) {
                randomStr =
                  randomStr + Math.floor(Math.random() * 10).toString();
              }
              let fileName =
                "/merchantscenter/banner/" +
                Sha1(
                  _this.$store.state.token + new Date().getTime() + randomStr
                ) +
                image.type;
              console.log(fileName);
              client.put(fileName, image).then((res) => {
                console.log(JSON.stringify(res));
                let imgUrl = "https://store.paitk.com/" + fileName;
                insert(imgUrl);
              });
            });
          });
        };
        editor.config.onchange = (html) => {
          this.htmlContent = html;
        };
        editor.create();
        editor.txt.html(this.bannerForm.content);
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handleAvatarSuccess(res, file) {
      console.log(JSON.stringify(res));
      console.log(JSON.stringify(file));
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type === "image/jpeg";
      // const isLt2M = file.size / 1024 / 1024 < 2;
      // if (!isJPG) {
      //   this.$message.error("上传头像图片只能是 JPG 格式!");
      // }
      // if (!isLt2M) {
      //   this.$message.error("上传头像图片大小不能超过 2MB!");
      // }
      // return isJPG && isLt2M;
      return true;
    },
    uploadImg(res) {
      console.log(res);
      console.log(res.file);
      let newFile = res.file;
      getOssAccessInfo().then((res) => {
        console.log(JSON.stringify(res));
        const client = new OSS({
          region: "oss-cn-hangzhou",
          accessKeyId: res.data.access_key_id,
          accessKeySecret: res.data.access_key_secret,
          bucket: "vodm-store-prod",
          stsToken: res.data.security_token,
        });
        console.log(client);
        let randomStr = "";
        for (var i = 0; i < 9; i++) {
          randomStr = randomStr + Math.floor(Math.random() * 10).toString();
        }
        let fileName =
          "/merchantscenter/banner/" +
          Sha1(this.$store.state.token + new Date().getTime() + randomStr) +
          "." +
          newFile.name.substring(newFile.name.lastIndexOf(".") + 1);
        console.log(fileName);
        client.put(fileName, newFile).then((res) => {
          console.log(JSON.stringify(res));
          this.bannerForm.img_url = "https://store.paitk.com/" + fileName;
          console.log(JSON.stringify(this.bannerForm));
          this.$message.success("banner上传成功");
        });
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    add() {
      console.log(JSON.stringify(this.bannerForm));
      if (this.bannerForm.banner_type == 1) {
        this.bannerForm.content = this.htmlContent;
      }
      bannerSaveOrUpdate({}, this.bannerForm).then((res) => {
        if (res.code == 0) {
          this.$message.success({
            duration: 1000,
            message: "banner修改成功",
          });
          this.$router.go(-1);
        } else {
          this.$message.error({ message: res.message });
        }
      });
    },
    editorChange(e) {
      this.htmlContent = e.target.innerHTML;
    },
  },
};
</script>

<style scoped>
.flex-left {
  display: flex;
  align-items: center;
}

.k-m-l-160 {
  margin-left: 160px;
}

.k-m-l-10 {
  margin-left: 10px;
}

.k-m-l-30 {
  margin-left: 30px;
}

.k-m-t {
  margin-top: 10px;
}

.avatar-uploader {
  border: 1px dashed #757070;
  border-radius: 6px;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
