<template>
  <div style="padding: 10px; margin: 10px; background-color: #fff">
    <div class="flex-between">
      <h2>{{ title }}</h2>
      <el-button class="btn1" type="primary" size="small" @click="bagck()"
        >返回</el-button
      >
    </div>

    <el-form
      size="small"
      label-position="left"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="140px"
      class="demo-ruleForm"
    >
      <el-form-item label="消息类型" prop="message_type">
        <el-select v-model="ruleForm.message_type" placeholder="系统消息">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="请输入消息标题" prop="message_title">
        <el-input
          clearable
          style="width: 380px"
          placeholder="请输入消息标题"
          v-model="ruleForm.message_title"
        ></el-input>
      </el-form-item>

      <el-form-item label="请输入消息内容" prop="message_content">
        <el-input
          style="width: 500px"
          placeholder="请输入消息内容"
          :autosize="{ minRows: 6, maxRows: 100 }"
          type="textarea"
          v-model="ruleForm.message_content"
        ></el-input>
      </el-form-item>

      <div class="flex-left">
        <!-- <div>当前时间</div> -->
        <el-form-item label="请选择通知时间" prop="notice_type">
          <div class="flex-left" style="height: 33px">
            <el-radio-group v-model="ruleForm.notice_type">
              <el-radio :label="0" @input="Timepros()">立即发送</el-radio>
              <el-radio style="margin: 0" :label="1">选择时间 ：</el-radio>
            </el-radio-group>

            <!-- <div style="margin-left:10px">选择时间 ：</div> -->
            <!-- v-if="ruleForm.notice_type == 1" -->

            <el-form-item prop="notice_time">
              <el-date-picker
                v-if="ruleForm.notice_type == 1"
                v-model="ruleForm.notice_time"
                type="datetime"
                :picker-options="pickerOptions"
                placeholder="选择日期时间"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
          </div>
        </el-form-item>

        <el-form-item
          label="推送类型"
          prop="push_type"
          label-width="90px"
          style="margin-left: 20px"
        >
          <el-radio-group v-model="ruleForm.push_type">
            <el-radio label="NOTICE">通知</el-radio>
            <el-radio label="MESSAGE">消息</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>

      <el-form-item label="活动性质" prop="push_user_type">
        <el-checkbox-group :max="1" v-model="ruleForm.push_user_type">
          <div class="flex-left">
            <el-checkbox :label="1" name="type">所有用户</el-checkbox>
            <el-checkbox :label="2" name="type">注册时间</el-checkbox>

            <!-- :disabled="ruleForm.push_user_type == 2 ? false : true" -->
            <el-form-item prop="value1">
              <el-date-picker
                style="margin-left: 10px"
                v-model="value1"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                :disabled="ruleForm.push_user_type == 2 ? false : true"
              >
              </el-date-picker>
            </el-form-item>
          </div>

          <div class="flex-left" style="margin: 15px 0">
            <el-checkbox
              style="margin-right: 25px"
              :label="3"
              name="marketing_ip"
            >
              所属IP
            </el-checkbox>

            <!-- :disabled="ruleForm.push_user_type == 3 ? false : true" -->

            <el-form-item prop="marketing_ip">
              <el-select
                style="margin-right: 10px"
                v-model="ruleForm.marketing_ip"
                multiple
                placeholder="请选择IP"
                :disabled="ruleForm.push_user_type == 3 ? false : true"
              >
                <el-option
                  v-for="item in ipList"
                  :key="item.id"
                  :label="item.classify_name"
                  :value="item.id"
                >
                  <img
                    :src="item.classify_icon"
                    style="height: 28px; float: left; margin-right: 10px"
                  />
                  <span style="float: left">{{ item.classify_name }}</span>
                </el-option>
              </el-select>
            </el-form-item>

            <!-- :disabled="ruleForm.push_user_type == 3 ? false : true" -->
            <el-form-item prop="region">
              <el-select
                v-model="ruleForm.region"
                placeholder="全部推广等级"
                :disabled="ruleForm.push_user_type == 3 ? false : true"
              >
                <!-- <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option> -->
              </el-select>
            </el-form-item>
          </div>

          <div class="flex-left">
            <el-checkbox style="margin-right: 10px" name="type" :label="4">
              指定用户</el-checkbox
            >

            <!-- :disabled="ruleForm.push_user_type == 4 ? false : true" -->
            <el-form-item prop="specify_user">
              <el-input
                :autosize="{ minRows: 5, maxRows: 10 }"
                style="width: 500px"
                type="textarea"
                placeholder="请输入用户手机号（用“,”隔开）"
                v-model="ruleForm.specify_user"
                maxlength="300"
                show-word-limit
                :disabled="ruleForm.push_user_type == 4 ? false : true"
              >
              </el-input>
            </el-form-item>
          </div>
        </el-checkbox-group>
      </el-form-item>

      <el-form-item label="跳转类型">
        <el-radio-group v-model="ruleForm.jump_type">
          <el-radio :label="1">跳转h5</el-radio>
          <el-radio :label="2">app内部导航</el-radio>
          <el-radio :label="3">富文本</el-radio>
          <el-radio :label="4">不跳转</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="H5链接:" v-show="ruleForm.jump_type == 1">
        <el-input
          v-model="ruleForm.jump_content"
          placeholder="请输入H5链接"
          clearable
        ></el-input>
      </el-form-item>

      <el-form-item label="APP路由:" v-show="ruleForm.jump_type == 2">
        <el-input
          v-model="ruleForm.jump_content"
          placeholder="请输入APP路由"
          clearable
        ></el-input>
      </el-form-item>

      <el-form-item>
        <div v-show="ruleForm.jump_type == 3">
          <div
            ref="editor"
            @input="editorChange"
            style="margin-top: 20px; width: 100%; height: 100%"
          />
        </div>
      </el-form-item>

      <el-form-item>
        <el-button type="info" @click="bagck()">取消</el-button>
        <el-button type="info" @click="submitStore()">保存到仓库</el-button>
        <el-button type="primary" @click="submit()">保存并发布</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
 
<script>
import {
  UpdateMessageApi,
  marketingIpApi,
  pushMessageApi,
} from "@/api/push_message.js";

import { getOssAccessInfo } from "@/api/common.js";
import Sha1 from "js-sha1";
var OSS = require("ali-oss");
import E from "wangeditor";

export default {
  name: "addMessage",
  components: {},
  props: {
    editItem: {
      type: Object,
      default: function () {
        return { message_push_id: null };
      },
    },
  },

  data() {
    return {
      pickerOptions: {
        //禁用当前日期之前的日期
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },

      title: "",

      options: [
        {
          value: 1,
          label: "系统消息",
        },
      ],

      ruleForm: {
        message_type: "",
        message_title: "",
        message_content: "",
        notice_time: "",
        push_type: "NOTICE",
        push_user_type: [],
        marketing_ip: [],
        specify_user: null,
        jump_content: "", //跳转内容
        jump_type: 4,
        notice_type: "",
        value1: "",
      },
      value1: "",

      ipList: [],

      rules: {
        message_type: [
          { required: true, message: "请选择消息类型", trigger: "change" },
        ],
        message_title: [
          { required: true, message: "请输入消息标题", trigger: "blur" },
        ],

        message_content: [
          { required: true, message: "请输入消息内容", trigger: "blur" },
        ],

        notice_type: [
          {
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
        push_type: [
          { required: true, message: "请选择推送类型", trigger: "change" },
        ],

        push_user_type: [
          {
            type: "array",
            required: true,
            message: "至少选择一个活动性质",
            trigger: "change",
          },
        ],

        // notice_time: [
        //   { required: true, message: "请选择时间", trigger: "blur" },
        // ],
        // {validator: validateTime}
        // { trigger: "submit", required: true, message: "请选择时间" }
        // value1: [{ trigger: "submit", required: true, message: "请选择时间" }],

        // marketing_ip: [
        //   { required: true, message: "请选择IP", trigger: "submit" },
        // ],

        // region: [
        //   { required: true, message: "选择推广等级", trigger: "change" },
        // ],

        // specify_user: [
        //   { required: true, message: "请输入用户手机号", trigger: "change" },
        // ],
      },
      editor: "",
      startTime: "",
    };
  },
  computed: {},
  watch: {},

  created() {
    this.initData();
    this.getIpList();
    this.editShow();
  },
  methods: {
    Timepros() {
      console.log("校验");
    },

    // 编辑回显 register_start_time register_end_time
    editShow() {
      if (this.editItem.message_push_id) {
        this.title = "编辑消息";
        this.ruleForm = this.editItem;
        if (this.ruleForm.push_user_type[0] !== 2) {
          this.value1 = "";
        } else {
          this.value1 = [
            this.editItem.register_start_time,
            this.editItem.register_end_time,
          ];
        }
      } else {
        this.title = "新增消息";
      }
    },

    // 保存到仓库
    async submitStore() {
      console.log("保存到仓库");

      await this.$refs.ruleForm.validate();

      if (this.ruleForm.push_user_type[0] == 2) {
        if (this.value1.length == 0) {
          return this.$message.error("请输入注册时间");
        }
        //  所属IP
        this.ruleForm.ipList = [];
        // 指定用户
        this.ruleForm.specify_user = "";
      }

      if (this.ruleForm.push_user_type[0] == 3) {
        if (this.ruleForm.marketing_ip.length == 0) {
          console.log("请选择IP");
          return this.$message.error("请选择IP");
        }

        this.ruleForm.register_end_time = "";
        this.ruleForm.register_start_time = "";
        this.value1 = "";

        // 指定用户
        this.ruleForm.specify_user = "";
      }

      if (this.ruleForm.push_user_type[0] == 4) {
        if (this.ruleForm.specify_user == null) {
          console.log("请选择手机号");
          return this.$message.error("请选择手机号");
        }
        this.ruleForm.register_end_time = "";
        this.ruleForm.register_start_time = "";
        this.value1 = "";

        //  所属IP
        this.ruleForm.ipList = [];
      }

      this.resJumpType();//校验

      // 保存
      const res = await UpdateMessageApi({
        ...this.ruleForm,
        register_start_time: this.value1[0],
        register_end_time: this.value1[1],
      });
      if (res.status == 1) {
        this.bagck();
        this.$message.success("成功保存到仓库");
      } else {
        this.$message.error(res.message);
      }
    },

    // 保存+推送提交
    async submit() {
      await this.$refs.ruleForm.validate();
      console.log("校验");
      if (this.ruleForm.push_user_type[0] == 2) {
        if (this.value1.length == 0) {
          return this.$message.error("请输入注册时间");
        }
      }

      if (this.ruleForm.push_user_type[0] == 3) {
        if (this.ruleForm.marketing_ip.length == 0) {
          console.log("请选择IP");
          return this.$message.error("请选择IP");
        }
        // else if(this.ruleForm.region.length == 0){
        //    this.$message.error("推广等级");
        // }
      }

      if (this.ruleForm.push_user_type[0] == 4) {
        if (this.ruleForm.specify_user == null) {
          console.log("请选择手机号");
          return this.$message.error("请选择手机号");
        }
      }
      
      this.resJumpType();//校验
      this.UpdateMessage();
    },

    //  保存+推送接口
    async UpdateMessage() {
      // console.log("此刻时间", this.ruleForm.notice_time, this.currentTime());

      // 保存
      const res = await UpdateMessageApi({
        ...this.ruleForm,
        register_start_time: this.value1[0],
        register_end_time: this.value1[1],
      });
      // 推送
      const res2 = await pushMessageApi({ message_push_id: res.data });
      if (res.status == 1 && res2.status == 1) {
        this.bagck();
        this.$message.success("保存并发布成功");
      } else {
        this.$message.error(res2.message);
        this.$message.error(res.message);
      }
    },

    // 校验跳转类型内容是否填写
    resJumpType() {
      if (this.ruleForm.jump_type == 1) {
        if (this.ruleForm.jump_content === "") {
          return this.$message.error("请输入H5链接");
        }
      }

      if (this.ruleForm.jump_type == 2) {
        if (this.ruleForm.jump_content === "") {
          return this.$message.error("请输入APP路由");
        }
      }

      if (this.ruleForm.jump_type == 3) {
        if (this.ruleForm.jump_content == "") {
          return this.$message.error("请输入富文本内容");
        }
      }
    },

    //  Ip列表
    async getIpList() {
      const res = await marketingIpApi();
      console.log(res);
      this.ipList = res.data.list;
    },

    // 富文本
    initData(id) {
      let _this = this;

      setTimeout(() => {
        const editor = new E(_this.$refs.editor);
        this.editor = editor;
        editor.config.zIndex = 10;
        editor.config.customUploadImg = function (files, insert) {
          files.forEach((image) => {
            getOssAccessInfo().then((res) => {
              const client = new OSS({
                region: "oss-cn-hangzhou",
                accessKeyId: res.data.access_key_id,
                accessKeySecret: res.data.access_key_secret,
                bucket: "vodm-store-prod",
                stsToken: res.data.security_token,
              });
              console.log(client);
              let randomStr = "";
              for (var i = 0; i < 9; i++) {
                randomStr =
                  randomStr + Math.floor(Math.random() * 10).toString();
              }
              let fileName =
                "/merchantscenter/banner/" +
                Sha1(
                  _this.$store.state.token + new Date().getTime() + randomStr
                ) +
                image.type;
              console.log(fileName);
              client.put(fileName, image).then((res) => {
                console.log(JSON.stringify(res));
                let imgUrl = "https://store.paitk.com/" + fileName;
                insert(imgUrl);
              });
            });
          });
        };
        editor.config.onchange = (html) => {
          this.ruleForm.jump_content = html; //富文本内容
        };
        editor.create();
        console.log(_this.ruleForm.jump_content);
        if (_this.editItem.jump_type === 4) {
          editor.txt.html(_this.editItem.jump_content);
        }
      }, 100);
    },
    editorChange(e) {
      this.htmlContent = e.target.innerHTML;
    },

    // 返回
    bagck() {
      this.$emit("bagck");
    },
  },
};
</script>

<style scoped lang='scss'>
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-left {
  display: flex;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>

<style>
.el-picker-panel .el-picker-panel__footer .el-button--text {
  display: none;
}
</style>