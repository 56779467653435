<template>
  <div style="background: white;padding: 10px;margin: 10px">

    <div>
      <el-button size="small" type="primary" @click="add()">新建授课老师</el-button>
    </div>

    <div style="margin-top: 10px">
      <el-input
          @clear="doSearch"
          size="small"
          placeholder="授课老师"
          v-model="queryParams.nick_name"
          clearable
          style="width: 120px;"
      >
      </el-input>
      <el-button
          size="small"
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-search"
          @click="doSearch()"
      >搜索</el-button>
    </div>

    <el-table
        border
        size="small"
        :data="tableData"
        style="width: 100%;margin-top: 10px">
      <el-table-column align="center" label="头像">
        <template slot-scope="scope">
          <img
              style="width: 60px;"
              :src="scope.row.head_picture"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" prop="nick_name" label="昵称"></el-table-column>
      <el-table-column align="center" label="个人标签">
        <template slot-scope="scope">
          <div style="display: flex;flex-direction: row;flex-wrap: wrap;">
            <span v-for="(item,index) in scope.row.label" :key="index" style="padding: 2px">
              <el-tag>{{item}}</el-tag>
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="introduction" label="个人简介"></el-table-column>
      <el-table-column align="center" prop="create_time" label="创建时间"></el-table-column>
      <el-table-column align="center" label="操作" >
        <template slot-scope="scope">
          <el-button
              type="text"
              @click="edit(scope.row)"
              size="small">
              编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-row style="margin-top: 24px;margin-right: 20px">
      <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="queryParams.page"
                    :page-size="queryParams.limit"
                    :total="total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
      </el-col>
    </el-row>


    <el-dialog
        size="small"
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        width="30%">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="130px" size="small">
        <el-form-item label="头像: ">
          <el-upload
              class="avatar-uploader"
              action=""
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              :http-request="uploadImg"
          >
            <img v-if="form.head_picture" :src="form.head_picture" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <span style=";color: rgba(166, 166, 166, 1);font-size: 12px;">建议上传尺寸为400*400px的jpg、png图片</span>
        </el-form-item>
        <el-form-item label="昵称:">
          <el-input  v-model="form.nick_name" placeholder="请输入老师昵称" clearable></el-input>
        </el-form-item>
        <el-form-item label="个人标签:">
          <el-input  v-model="form.label_input" @keyup.enter.native="onLabelSubmit" placeholder="请输入个人标签，按回车添加" clearable></el-input>
          <div style="display: flex;flex-direction: row;flex-wrap: wrap; margin-top: 10px">
            <span v-for="(item,index) in form.label" :key="index" style="padding: 2px">
              <el-tag>{{item}}<i class="el-icon-delete" style="margin-left: 10px" @click="labelDel(index)"></i></el-tag>
            </span>
          </div>

        </el-form-item>
        <el-form-item label="个人简介:">
          <el-input  v-model="form.introduction" type="textarea" maxlength="80" show-word-limit placeholder="请输入个人简介" clearable></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false">取 消</el-button>
          <el-button size="small" type="primary" @click="submit">确 定</el-button>
        </span>
    </el-dialog>

  </div>
</template>

<script>
import { courseTeacherList ,saveOrUpdate } from "../../api/course/courseTeacher";
import {getOssAccessInfo} from "../../api/common";
import Sha1 from "js-sha1";
var OSS = require("ali-oss");

export default {
  name: "teacher",
  data(){
    return{
      dialogTitle:'',
      dialogVisible:false,
      form:{
        label_input: '',
        head_picture: '',
        label:[]
      },
      rules:{},
      queryParams:{
        nick_name: '',
        page: 1,
        limit: 10,
        sidx: "create_time",
        order: "desc"
      },
      total:0,
      listLoading:false,
      tableData: []
    }
  },
  mounted(){
    this.initData()
  },
  methods:{
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handleAvatarSuccess(res, file) {
      console.log(JSON.stringify(res))
      console.log(JSON.stringify(file))
    },
    beforeAvatarUpload(file) {
      return true
    },
    uploadImg(res){
      console.log(res)
      console.log(res.file)
      let newFile = res.file
      getOssAccessInfo().then(res => {
        console.log(JSON.stringify(res))
        const client = new OSS({
          region: "oss-cn-hangzhou",
          accessKeyId: res.data.access_key_id,
          accessKeySecret: res.data.access_key_secret,
          bucket: "vodm-store-prod",
          stsToken: res.data.security_token
        });
        console.log(client)
        let randomStr = "" ;
        for (var i = 0; i < 9; i++) {
          randomStr =randomStr +Math.floor(Math.random() * 10).toString();
        }
        let fileName =  "/merchantscenter/course/teacher/" + Sha1(this.$store.state.token + new Date().getTime() + randomStr) + newFile.type;
        console.log(fileName)
        client.put(fileName, newFile).then(res =>{
          console.log(JSON.stringify(res));
          this.form.head_picture = "https://store.paitk.com" + fileName;
          console.log(JSON.stringify(this.form))
          this.$message.success("头像上传成功");
        })
      })
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    doSearch(){
      this.queryParams.page = 1;
      this.initData();
    },
    add(){
      this.dialogTitle = '新建授课老师'
      this.dialogVisible = true
      this.form = {
        head_picture: '',
        label:[]
      }
    },
    edit(row){
      this.dialogTitle = '编辑授课老师'
      this.dialogVisible = true
      this.form = JSON.parse(JSON.stringify(row))
    },
    onLabelSubmit(){
      console.log(JSON.stringify(this.form))
      this.form.label.push(this.form.label_input)
      this.form.label_input = ''
    },
    labelDel(index){
      this.form.label.splice(index, 1);
    },
    submit(){
      saveOrUpdate({},this.form).then(res=> {
        if(res.code==0) {
          this.$message.success({
            duration: 1000,
            message: '操作成功'
          });
          this.initData()
          this.dialogVisible = false
        }else{
          this.$message.error({message: res.message});
        }
      })
    },
    initData(){
      this.listLoading = true
      courseTeacherList(this.queryParams).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total_count;
        this.listLoading = false;
      });
    },
  }
}
</script>

<style scoped>
.avatar-uploader{
  border: 1px dashed #757070;
  border-radius: 6px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
